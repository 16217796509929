import React from 'react';

import { Box, CircularProgress } from '@mui/material';

const PageLoader: React.FC = () => (
  <Box width="100%" height="100%" display="flex" justifyContent="center" alignItems="center">
    <CircularProgress />
  </Box>
);

export default PageLoader;
