import { Components } from '@mui/material';

export default {
  styleOverrides: {
    root: {
      color: '#1EAEA1',
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '20px',
      fontFamily: 'Poppins',
    },
  },
} as Components;
