import { Components } from '@mui/material';

export default {
  styleOverrides: {
    root: {
      '&.MuiOutlinedInput-root': { borderRadius: 12 },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'rgba(125, 127, 131, 0.32)',
      },
      '& textarea.MuiInputBase-input.MuiOutlinedInput-input': { padding: 0 },
    },
    input: {
      '&.MuiOutlinedInput-input': { height: '26px', padding: '7px 16px', fontSize: '14px', lineHeight: '26px' },
      '&.MuiInputBase-inputAdornedStart': { padding: '7px 16px 7px 0' },
      '&[type=number]': {
        textAlign: 'center',
        paddingRight: '5px',
        paddingLeft: '5px',
        '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
          WebkitAppearance: 'none',
          margin: 0,
        },
        MozAppearance: 'textfield',
      },
      '&::placeholder': {
        color: '#7D7F83',
        opacity: 1,
      },
    },
    adornedStart: {
      '& .MuiIconButton-root': { padding: 0 },
    },
    adornedEnd: {
      '& .MuiIconButton-root': { padding: 0 },
    },
    textarea: { '&.MuiInputBase-input.MuiOutlinedInput-input': { padding: 0 } },
  },
} as Components;
