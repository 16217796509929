import { Components } from '@mui/material';

import MuiAlert from './MuiAlert';
import MuiAutocomplete from './MuiAutocomplete';
import MuiBackdrop from './MuiBackdrop';
import MuiChip from './MuiChip';
import MuiFormControlLabel from './MuiFormControlLabel';
import MuiFormHelperText from './MuiFormHelperText';
import MuiInputBase from './MuiInputBase';
import MuiLink from './MuiLink';
import MuiList from './MuiList';
import MuiMenuItem from './MuiMenuItem';
import MuiPickersMonth from './MuiPickersMonth';
import MuiPickersPopper from './MuiPickersPopper';
import MuiPickersYear from './MuiPickersYear';
import MuiPopover from './MuiPopover';
import MuiSvgIcon from './MuiSvgIcon';
import MuiSwitch from './MuiSwitch';
import MuiTableSortLabel from './MuiTableSortLabel';
import MuiTabs from './MuiTabs';
import MuiTextField from './MuiTextField';

export default {
  MuiPopover,
  MuiMenuItem,
  MuiList,
  MuiAlert,
  MuiTextField,
  MuiFormHelperText,
  MuiInputBase,
  MuiSvgIcon,
  MuiFormControlLabel,
  MuiChip,
  MuiAutocomplete,
  MuiSwitch,
  MuiPickersPopper,
  MuiPickersMonth,
  MuiPickersYear,
  MuiBackdrop,
  MuiLink,
  MuiTabs,
  MuiTableSortLabel,
} as Components;
