import palette from './palette';

export default {
  fontFamily: 'Poppins',
  h1: {
    color: palette.text?.primary,
    fontWeight: 500,
    fontSize: '64px',
    lineHeight: '80px',
  },
  h2: {
    color: palette.text?.primary,
    fontWeight: 500,
    fontSize: '48px',
    lineHeight: '64px',
  },
  h3: {
    color: palette.text?.primary,
    fontWeight: 500,
    fontSize: '36px',
    lineHeight: '54px',
  },
  h4: {
    color: palette.text?.primary,
    fontWeight: 500,
    fontSize: '24px',
    lineHeight: '36px',
  },
  h5: {
    color: palette.text?.primary,
    fontWeight: 500,
    fontSize: '20px',
    lineHeight: '30px',
  },
  h6: {
    color: palette.text?.primary,
    fontWeight: 500,
    fontSize: '18px',
    lineHeight: '28px',
  },
  subtitle1: {
    color: palette.text?.primary,
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '12px',
  },
  subtitle2: {
    color: palette.text?.primary,
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '26px',
  },
  subtitle3: {
    color: palette.text?.primary,
    fontWeight: 500,
    fontSize: '13px',
    lineHeight: '24px',
  },
  body1: {
    color: palette.text?.primary,
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
  },
  body2: {
    color: palette.text?.primary,
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '26px',
    whiteSpace: 'break-spaces',
  },
  body3: {
    color: palette.text?.primary,
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '22px',
  },
  caption: {
    color: palette.text?.primary,
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '20px',
  },
  overline: {
    color: palette.text?.primary,
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '20px',
  },
  button: {
    color: palette.text?.primary,
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '24px',
  },
};
