import { Components } from '@mui/material';

import palette from '../palette';

export default {
  styleOverrides: {
    root: {},
    monthButton: {
      fontSize: '14px',
      '&.Mui-selected': { color: palette.common?.white, backgroundColor: palette.hover?.primaryMain },
    },
  },
} as Components;
