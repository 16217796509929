import { PaletteOptions } from '@mui/material/styles';

export default {
  common: {
    black: '#000000',
    white: '#FFFFFF',
  },
  primary: { main: '#1EAEA1', light: 'rgba(30, 174, 161, 0.08)' },
  secondary: {
    main: 'rgba(125, 127, 131, 0.16)',
    light: 'rgba(125, 127, 131, 0.08)',
    dark: 'rgba(125, 127, 131, 0.8)',
  },
  divider: 'rgba(125, 127, 131, 0.24)',
  text: { primary: '#1D2025' },
  background: { default: '#171A1D' },
  action: { selected: '#A2FFE1', disabled: '#7D7F83' },
  error: { main: '#EF4450' },
  hover: {
    primaryMain: '#158C83',
    errorMain: '#E2283E',
    navbar: { primary: 'rgba(30, 174, 161, 0.16)', secondary: 'rgba(30, 174, 161, 0.48)' },
  },
  customGrey: { icon: '#52555A' },
  customBackground: {
    primaryLight: '#DBF2F0',
    primaryLight2: '#CFF8F0',
    primaryLight3: '#EDF9F7',
    primaryLightTransparent: 'rgba(30, 174, 161, 0.12)',
    greyLight: 'rgba(125, 127, 131, 0.8)',
    greyLight2: '#F9FAFA',
  },
  customHover: {
    primaryAlt: '#66E2D0',
    red: 'rgba(243, 64, 77, 0.08)',
  },
  green: { text: '#399425', background: 'rgba(74, 179, 52, 0.12)' },
  red: { text: '#F3404D', background: 'rgba(243, 64, 77, 0.48)' },
} as PaletteOptions;
