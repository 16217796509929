import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

import sprite from './iconography.svg';

interface IconProps extends SvgIconProps {
  iconName: string;
  size?: string;
}

const Icon = ({ iconName, htmlColor, size = '24px', viewBox, height, ...props }: IconProps) => (
  <SvgIcon
    {...props}
    htmlColor={htmlColor}
    viewBox={viewBox}
    sx={{ fontSize: size, width: size, height, transitionDuration: '250ms', transitionProperty: 'width' }}
    data-testid={`icon-${iconName}`}
  >
    <use href={`${sprite}#${iconName}`} />
  </SvgIcon>
);
export default Icon;
