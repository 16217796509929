import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import { styled, Theme, CSSObject } from '@mui/material/styles';

const drawerWidth = 180;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: 80,
});

export const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  height: 'calc(100vh - 40px)',
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  '& .MuiPaper-root': {
    padding: 16,
    position: 'inherit',
    backgroundColor: theme.palette.background.default,
    border: 'none',
    borderRadius: 20,
  },
  ...(open && {
    margin: 20,
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    margin: 20,
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

export const StyledList = styled(List)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 4,
  padding: '24px 0',
}));

export const StyledListItem = styled(ListItem, {
  shouldForwardProp: (prop) => prop !== 'isActive',
})(({ isActive, theme }: { isActive: boolean; theme: Theme }) => ({
  backgroundColor: isActive ? theme.palette.action.selected : 'transparent',
  borderRadius: 16,
  '&:hover': {
    backgroundColor: !isActive && theme.palette.hover.navbar.primary,
  },
}));

export const StyledListItemButton = styled(ListItemButton)(() => ({
  padding: '8px 12px',
  gap: 12,
}));

export const LogoutButton = styled(ListItemButton)(({ theme }) => ({
  maxHeight: 48,
  marginTop: 'auto',
  backgroundColor: `${theme.palette.primary.main}24`,
  padding: '8px 12px',
  gap: 12,
  borderRadius: 16,
  '&:hover': {
    backgroundColor: theme.palette.hover.navbar.secondary,
  },
}));
