import React from 'react';

import { Alert } from '@mui/material';
import MuiSnackbar from '@mui/material/Snackbar';
import { useDispatch, useSelector } from 'react-redux';

import { hideSnackbar } from 'src/redux/slices/snackbarSlice';
import { AppDispatch, RootState } from 'src/redux/store';

import Icon from '../Icon';

const Snackbar: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();

  const { isOpen, message, variant } = useSelector((state: RootState) => state.snackbar);

  const closeSnackbar = () => {
    dispatch(hideSnackbar());
  };

  return (
    <MuiSnackbar
      open={isOpen}
      autoHideDuration={50090}
      onClose={closeSnackbar}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      data-testid="snackbar"
    >
      <Alert
        onClose={closeSnackbar}
        severity={variant}
        variant="filled"
        iconMapping={{
          error: <Icon iconName="snackbar-error" />,
          success: <Icon iconName="snackbar-success" />,
        }}
        slots={{
          closeIcon: (props) => (
            <Icon {...props} iconName="close" size="20px" viewBox="0 0 20 20" data-testid="snackbar-close" />
          ),
        }}
      >
        {message}
      </Alert>
    </MuiSnackbar>
  );
};

export default Snackbar;
