import React, { Suspense, lazy } from 'react';

import { jwtDecode } from 'jwt-decode';
import { Routes as Switch, Route, Navigate } from 'react-router-dom';

import PageLoader from 'src/components/PageLoader';
import { USER_ROLES, ROUTES, DEFAULT_ROUTE } from 'src/constants';
import ResetPassword from 'src/pages/ResetPassword';
import { getCookie } from 'src/utils/cookie';

const Dashboard = lazy(() => import('src/pages/Dashboard'));
const Login = lazy(() => import('src/pages/Login'));
const Donatons = lazy(() => import('src/pages/Donations'));
const ManageDonation = lazy(() => import('src/pages/Donations/ManageDonation'));
const DonationDetails = lazy(() => import('src/pages/Donations/DonationDetails'));
const ManageLocation = lazy(() => import('src/pages/Locations/ManageLocation'));
const LocationDetails = lazy(() => import('src/pages/Locations/LocationDetails'));
const ManageClient = lazy(() => import('src/pages/Clients/ManageClient'));
const ClientDetails = lazy(() => import('src/pages/Clients/ClientDetails'));
const ManageDonee = lazy(() => import('src/pages/Donees/ManageDonee'));
const DoneeDetails = lazy(() => import('src/pages/Donees/DoneeDetails'));
const Users = lazy(() => import('src/pages/Users'));
const Donees = lazy(() => import('src/pages/Donees'));
const Clients = lazy(() => import('src/pages/Clients'));
const Locations = lazy(() => import('src/pages/Locations'));
const ClaimDonation = lazy(() => import('src/pages/ClaimDonation'));
const PickupDonation = lazy(() => import('src/pages/PickupDonation'));
const Uploads = lazy(() => import('src/pages/Uploads'));

const Routes: React.FC = () => {
  const token = getCookie('token');
  const role = token ? jwtDecode<{ role: USER_ROLES }>(token).role : null;

  const defaultRoute = role ? DEFAULT_ROUTE[role] : ROUTES.login;

  const privateRoutesConfig = [
    { path: ROUTES.data, element: Dashboard, requiredRole: [USER_ROLES.admin, USER_ROLES.client] },
    {
      path: ROUTES.donations,
      element: Donatons,
      requiredRole: [USER_ROLES.admin, USER_ROLES.client, USER_ROLES.locationUser],
    },
    {
      path: ROUTES.createDonation,
      element: ManageDonation,
      requiredRole: [USER_ROLES.admin, USER_ROLES.client, USER_ROLES.locationUser],
    },
    {
      path: `${ROUTES.clients}/:companyId${ROUTES.locations}/:locationId${ROUTES.donations}/:donationId${ROUTES.edit}`,
      element: ManageDonation,
      requiredRole: [USER_ROLES.admin, USER_ROLES.client, USER_ROLES.locationUser],
    },
    {
      path: `${ROUTES.clients}/:companyId${ROUTES.locations}/:locationId${ROUTES.donations}/:donationId${ROUTES.details}`,
      element: DonationDetails,
      requiredRole: [USER_ROLES.admin, USER_ROLES.client, USER_ROLES.locationUser],
    },
    { path: ROUTES.users, element: Users, requiredRole: [USER_ROLES.admin] },
    { path: ROUTES.donees, element: Donees, requiredRole: [USER_ROLES.admin] },
    {
      path: `${ROUTES.donees}${ROUTES.create}`,
      element: ManageDonee,
      requiredRole: [USER_ROLES.admin],
    },
    {
      path: `${ROUTES.donees}/:doneeId${ROUTES.edit}`,
      element: ManageDonee,
      requiredRole: [USER_ROLES.admin],
    },
    {
      path: `${ROUTES.donees}/:doneeId${ROUTES.details}`,
      element: DoneeDetails,
      requiredRole: [USER_ROLES.admin],
    },
    { path: ROUTES.clients, element: Clients, requiredRole: [USER_ROLES.admin] },
    {
      path: `${ROUTES.clients}${ROUTES.create}`,
      element: ManageClient,
      requiredRole: [USER_ROLES.admin],
    },
    {
      path: `${ROUTES.clients}/:companyId${ROUTES.edit}`,
      element: ManageClient,
      requiredRole: [USER_ROLES.admin],
    },
    {
      path: `${ROUTES.clients}/:companyId${ROUTES.details}`,
      element: ClientDetails,
      requiredRole: [USER_ROLES.admin],
    },
    { path: ROUTES.locations, element: Locations, requiredRole: [USER_ROLES.admin, USER_ROLES.client] },
    {
      path: `${ROUTES.clients}/:companyId${ROUTES.locations}${ROUTES.create}`,
      element: ManageLocation,
      requiredRole: [USER_ROLES.admin, USER_ROLES.client],
    },
    {
      path: `${ROUTES.clients}/:companyId${ROUTES.locations}/:locationId${ROUTES.edit}`,
      element: ManageLocation,
      requiredRole: [USER_ROLES.admin, USER_ROLES.client],
    },
    {
      path: `${ROUTES.clients}/:companyId${ROUTES.locations}/:locationId${ROUTES.details}`,
      element: LocationDetails,
      requiredRole: [USER_ROLES.admin, USER_ROLES.client],
    },
    { path: ROUTES.uploads, element: Uploads, requiredRole: [USER_ROLES.admin] },
  ];

  return (
    <Suspense fallback={<PageLoader />}>
      <Switch>
        <Route path="/" element={<Navigate to={defaultRoute} />} />
        <Route path={ROUTES.login} element={<Login />} />
        <Route path={ROUTES.resetPassword} element={<ResetPassword />} />
        <Route path={ROUTES.claimDonation} element={<ClaimDonation />} />
        <Route path={ROUTES.pickupDonation} element={<PickupDonation />} />

        {privateRoutesConfig.map(({ path, element: Element, requiredRole }) => (
          <Route
            key={path}
            path={path}
            element={role && requiredRole.includes(role) ? <Element /> : <Navigate to={ROUTES.login} />}
          />
        ))}
      </Switch>
    </Suspense>
  );
};

export default Routes;
