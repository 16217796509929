import { Components } from '@mui/material';

export default {
  styleOverrides: {
    root: {},
    paper: {
      marginTop: '4px',
      borderRadius: '16px',
      boxShadow: '0px 0px 2px 0px rgba(125, 127, 131, 0.24), 0px 20px 40px -4px rgba(125, 127, 131, 0.24);',
    },
  },
} as Components;
