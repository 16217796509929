import React, { useState } from 'react';

import { Typography, useTheme } from '@mui/material';
import MuiTextField from '@mui/material/TextField';
import { useField } from 'formik';

import IconButton from 'src/components/IconButton';
import { ICON_BUTTON_VARIANTS } from 'src/components/IconButton/IconButton';

import { FieldWrapper } from './styled';

interface TextFieldProps {
  name: string;
  label: string;
  type?: string;
  multiline?: boolean;
  rows?: number;
  isArray?: boolean;
  isWholeNumber?: boolean;
  step?: number;
  placeholder?: string;
  disabled?: boolean;
  min?: number;
  max?: number;
  handleChangeValues?: (field: string, values: unknown) => void;
}

const TextField: React.FC<TextFieldProps> = ({
  name,
  label,
  type,
  multiline,
  rows,
  isArray = false,
  isWholeNumber = false,
  step = 1,
  placeholder,
  disabled = false,
  max,
  min = 0,
  handleChangeValues,
}) => {
  const theme = useTheme();
  const [field, meta, helpers] = useField(name);

  const [arrayValue, setArrayValue] = useState(isArray ? field.value?.join('; ') : '');

  const handleFormValueChange = (value: string | string[] | number) => {
    helpers.setValue(value);
    handleChangeValues?.(name, value);
  };

  const handleChangeArray = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setArrayValue(newValue);

    const newValueArray = newValue
      .split(';')
      .map((item) => item.trim())
      .filter((item) => item);
    handleFormValueChange(newValueArray);
  };

  const handleWholeNumber = (value: string) => Number(value.replace(/[^\d]/g, ''));

  const handleDecimalNumber = (value: string) => {
    value = value.replace(',', '.');
    const parts = value.split('.');

    if (parts.length > 2) return value;

    if (parts[1] && parts[1].length > 2) {
      parts[1] = parts[1].slice(0, 2);
      value = parts.join('.');
    }

    return Number(value);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (isArray) {
      return handleChangeArray(event);
    }

    let newValue: string | number = event.target.value;

    if (type === 'number') {
      newValue = isWholeNumber ? handleWholeNumber(newValue) : handleDecimalNumber(newValue);
    }

    handleFormValueChange(newValue);
  };

  const handleIncrement = () => {
    if (!field.value && max !== undefined) {
      handleFormValueChange(max >= 1 ? 1 : max);
      return;
    }
    handleFormValueChange(Number((Number(field.value || 0) + step).toFixed(2)));
  };

  const handleDecrement = () => {
    if (!field.value && min !== undefined) {
      handleFormValueChange(min <= 0 ? 0 : min);

      return;
    }
    handleFormValueChange(Number((Number(field.value || 0) - step).toFixed(2)));
  };

  return (
    <FieldWrapper data-testid="textfield-wrapper">
      <Typography
        variant="subtitle2"
        component="p"
        color={disabled ? theme.palette.secondary.dark : theme.palette.primary.contrastText}
      >
        {label}
      </Typography>

      <MuiTextField
        {...field}
        value={isArray ? arrayValue : field.value}
        onChange={handleChange}
        type={type}
        variant="outlined"
        fullWidth
        placeholder={label}
        error={meta.touched && Boolean(meta.error)}
        helperText={meta.touched && meta.error ? meta.error : '' || placeholder}
        multiline={multiline}
        rows={rows}
        disabled={disabled}
        data-testid="textfield"
        inputProps={{ 'data-testid': 'textfield-input' }}
        InputProps={
          type === 'number'
            ? {
                startAdornment: (
                  <IconButton
                    iconName="minus"
                    variant={ICON_BUTTON_VARIANTS.transparentMedium}
                    onClick={handleDecrement}
                    disabled={min !== undefined && field.value <= min}
                  />
                ),
                endAdornment: (
                  <IconButton
                    iconName="plus"
                    variant={ICON_BUTTON_VARIANTS.transparentMedium}
                    onClick={handleIncrement}
                    disabled={max !== undefined && field.value >= max}
                  />
                ),
              }
            : undefined
        }
      />
    </FieldWrapper>
  );
};

export default TextField;
