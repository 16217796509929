import React from 'react';

import ExternalPageWrapper from 'src/components/ExternalPageWrapper';

import ResetPasswordForm from './ResetPasswordForm';

const ResetPassword: React.FC = () => {
  return (
    <ExternalPageWrapper>
      <ResetPasswordForm />
    </ExternalPageWrapper>
  );
};

export default ResetPassword;
