import { Middleware } from '@reduxjs/toolkit';

import { logout } from '../slices/authSlice';
import { resetCompanies } from '../slices/companiesSlice';
import { resetDonations } from '../slices/donationsSlice';
import { resetDonees } from '../slices/doneesSlice';
import { resetForm } from '../slices/formSlice';
import { resetLocations } from '../slices/locationsSlice';
import { resetOrgTypes } from '../slices/organizationTypesSlice';
import { resetUsers } from '../slices/usersSlice';

const logoutMiddleware: Middleware =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    if (logout.match(action)) {
      dispatch(resetUsers());
      dispatch(resetCompanies());
      dispatch(resetDonees());
      dispatch(resetLocations());
      dispatch(resetOrgTypes());
      dispatch(resetForm());
      dispatch(resetDonations());
    }
    return next(action);
  };

export default logoutMiddleware;
