import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

import { StyledIconButtonProps } from './types';

export const StyledButton = styled(Button, {
  shouldForwardProp: (prop) =>
    prop !== 'textColor' &&
    prop !== 'backgroundColor' &&
    prop !== 'hoverColor' &&
    prop !== 'padding' &&
    prop !== 'fonrSize' &&
    prop !== 'lineHeight' &&
    prop !== 'border',
})<StyledIconButtonProps>(
  ({ textColor, backgroundColor, hoverColor, border, padding, fontSize, lineHeight, theme }) => ({
    minWidth: 'auto',
    backgroundColor,
    border: border,
    borderRadius: 12,
    padding,
    gap: '8px',
    '& span': {
      fontSize,
      lineHeight,
      color: textColor,
      textTransform: 'none',
    },
    '&.Mui-disabled': { backgroundColor: theme.palette.divider },
    '&.Mui-disabled span': { color: theme.palette.secondary.dark },
    '&:hover': {
      backgroundColor: hoverColor,
    },
  }),
);
