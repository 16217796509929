import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import axios from 'src/axios';
import { DEFAULT_ERROR } from 'src/constants/snackbar';
import { deleteCookie, setCookie } from 'src/utils/cookie';

import { ErrorResponse } from '../types';
import { AuthState, LoginActionResult, LoginPayload } from '../types/auth';

export const initialState: AuthState = {
  user: null,
  loading: false,
  error: '',
};

export const login = createAsyncThunk<LoginActionResult, LoginPayload>(
  'auth/login',
  async (credentials, { rejectWithValue }) => {
    try {
      const response = await axios.post<string>('/auth/login', credentials);
      return { token: response.data, stayLoggedIn: credentials.stayLoggedIn };
    } catch (error) {
      const axiosError = error as AxiosError<ErrorResponse>;
      const message = axiosError.response?.data.message ?? DEFAULT_ERROR;
      return rejectWithValue(message);
    }
  },
);

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout(state) {
      state.user = initialState.user;
      state.loading = initialState.loading;
      state.error = initialState.error;
      deleteCookie('token');
    },
  },
  extraReducers: (builder) => {
    builder.addCase(login.pending, (state) => {
      state.loading = true;
      state.error = '';
    });
    builder.addCase(login.fulfilled, (state, action: PayloadAction<LoginActionResult>) => {
      state.loading = false;
      setCookie('token', action.payload.token, action.payload.stayLoggedIn ? 7 : 1); // days
    });
    builder.addCase(login.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
  },
});

export const { logout } = authSlice.actions;

export default authSlice.reducer;
