import { AlertColor } from '@mui/material';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SnackbarState } from '../types/snackbar';

export const initialState: SnackbarState = {
  isOpen: false,
  message: '',
  variant: 'success',
};

const snackbarSlice = createSlice({
  name: 'snackbar',
  initialState,
  reducers: {
    showSnackbar(state, action: PayloadAction<{ message: string; variant: AlertColor }>) {
      state.isOpen = true;
      state.message = action.payload.message;
      state.variant = action.payload.variant;
    },
    hideSnackbar(state) {
      state.isOpen = false;
    },
  },
});

export const { showSnackbar, hideSnackbar } = snackbarSlice.actions;

export default snackbarSlice.reducer;
