import { Components } from '@mui/material';

import palette from '../palette';

export default {
  styleOverrides: {
    root: {
      marginTop: 4,
      marginLeft: 0,
      marginRight: 0,
      color: palette.customGrey?.icon,
    },
  },
} as Components;
