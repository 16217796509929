import { Components } from '@mui/material';

import palette from '../palette';

export default {
  styleOverrides: {
    root: {
      '& .MuiButtonBase-root': {
        textTransform: 'none',
        fontFamily: 'Poppins',
        fontSize: '13px',
        lineHeight: '26px',
        fontWeight: 500,
      },
      '& .MuiButtonBase-root.MuiTab-root.Mui-selected': {
        color: palette.text?.primary,
      },
      '& .MuiTabs-indicator': {
        backgroundColor: palette.text?.primary,
      },
    },
  },
} as Components;
