export enum POPOVER_MODS {
  filter = 'filter',
  details = 'details',
  edit = 'edit',
  create = 'create',
  upload = 'upload',
}

export interface PopoverState {
  mode: POPOVER_MODS;
  title: string;
  mainBtnLabel: string;
  mainBtnIconName?: string;
  secondaryBtnLabel: string;
  width: string;
  onClose?: () => void;
  onSecondaryBtnClick?: () => void;
  onMainBtnClick?: (values?: unknown[]) => void;
}
