import React, { ReactNode, useState } from 'react';

import { PopoverState } from 'src/types/popover';

import { PopoverContext } from './popoverContext';

interface PopoverProviderProps {
  children: ReactNode;
}

const PopoverProvider: React.FC<PopoverProviderProps> = ({ children }) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);
  const [popoverConfig, setPopoverConfig] = useState<PopoverState | null>(null);

  const handleOpenPopover = () => {
    setIsPopoverOpen(true);
  };

  const handleClosePopover = () => {
    setIsPopoverOpen(false);
    setTimeout(() => setPopoverConfig(null), 300);
  };

  const handleSetPopoverConfig = (config: PopoverState) => {
    setPopoverConfig(config);
  };

  const value = { isPopoverOpen, popoverConfig, handleOpenPopover, handleClosePopover, handleSetPopoverConfig };

  return <PopoverContext.Provider value={value}>{children}</PopoverContext.Provider>;
};

export default PopoverProvider;
