import { ManipulateType } from 'dayjs';

import { RangeUnit } from 'src/types';

export enum ROUTES {
  data = '/data',
  donations = '/donations',
  createDonation = '/donations/create',
  clients = '/clients',
  donees = '/donees',
  users = '/users',
  uploads = '/uploads',
  locations = '/locations',
  create = '/create',
  login = '/login',
  resetPassword = '/reset',
  details = '/details',
  edit = '/edit',
  claimDonation = '/claim-donation',
  pickupDonation = '/pickup',
}

export enum USER_ROLES {
  admin = 'super-admin',
  client = 'company-admin',
  locationUser = 'location-admin',
}

export const NAV_BAR_ITEMS = {
  orders: { iconName: 'orders', text: 'Donations', path: ROUTES.donations },
  data: { iconName: 'data', text: 'Data', path: ROUTES.data },
  locations: { iconName: 'locations', text: 'Locations', path: ROUTES.locations },
  clients: { iconName: 'clients', text: 'Clients', path: ROUTES.clients },
  donees: { iconName: 'donees', text: 'Donees', path: ROUTES.donees },
  users: { iconName: 'users', text: 'Users', path: ROUTES.users },
  uploads: { iconName: 'uploads', text: 'Uploads', path: ROUTES.uploads },
};

export const NAV_BAR_ITEMS_BY_ROLE = {
  [USER_ROLES.locationUser]: [NAV_BAR_ITEMS.orders],
  [USER_ROLES.client]: [NAV_BAR_ITEMS.data, NAV_BAR_ITEMS.locations, NAV_BAR_ITEMS.orders],
  [USER_ROLES.admin]: [
    NAV_BAR_ITEMS.data,
    NAV_BAR_ITEMS.orders,
    NAV_BAR_ITEMS.clients,
    NAV_BAR_ITEMS.donees,
    NAV_BAR_ITEMS.users,
    NAV_BAR_ITEMS.uploads,
  ],
};

export const DEFAULT_ROUTE = {
  [USER_ROLES.admin]: ROUTES.data,
  [USER_ROLES.client]: ROUTES.data,
  [USER_ROLES.locationUser]: ROUTES.donations,
};

export enum VIEWS {
  table,
  calendar,
}

export const DEFAULT_PAGE_NUMBER = 1;
export const DEFAULT_LIMIT = 9999999;
export const PASSWORD_MIN_LENGTH = 8;
export const MAX_CSV_FILE_SIZE = 2097152;
export const MAX_FILE_SIZE = 5242880;

export const WEEK_DAYS_SHORT = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'];
export const MONTHS_SHORT = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];

export const DateManipulateType: Record<RangeUnit, ManipulateType> = {
  [RangeUnit.day]: RangeUnit.day,
  [RangeUnit.week]: 'week',
  [RangeUnit.month]: RangeUnit.month,
  [RangeUnit.year]: RangeUnit.year,
};
