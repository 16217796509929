import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

export const FormWrapper = styled(Box)(() => ({
  width: '100%',
  maxWidth: '432px',
  margin: '84px auto',
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
}));
