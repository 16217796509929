export const DEFAULT_ERROR = 'Something went wrong';

// users
export const FORGOT_PASSWORD_SUCCESS = 'Please check your email';
export const FORGOT_PASSWORD_REJECTED = 'User not found';
export const GET_USER_REJECTED = 'Cannot get user';
export const GET_USERS_REJECTED = 'Cannot get users';
export const CREATE_USER_SUCCESS = 'The user has been successfully created';
export const UPDATE_USER_SUCCESS = 'The user has been successfully updated';
export const CREATE_USER_REJECTED = 'Cannot create user';
export const RESEND_INVITATION_SUCCESS = 'The invitations have been successfully resent';

// donees
export const GET_DONEES_REJECTED = 'Cannot get donees';
export const CREATE_DONEE_SUCCESS = 'The donee has been successfully created';
export const UPDATE_DONEE_SUCCESS = 'The donee has been successfully updated';
export const CREATE_DONEE_REJECTED = 'Cannot create donee';

// donations
export const GET_DONATIONS_REJECTED = 'Cannot get donations';
export const GET_DONATION_REJECTED = 'Cannot get this donation';
export const CREATE_DONATION_SUCCESS = 'The donation has been successfully created';
export const CREATE_DONATION_REJECTED = 'Cannot create donation';
export const UPDATE_DONATION_SUCCESS = 'The donation has been successfully updated';
export const PUBLISH_DONATION = 'The donation is published';
export const SEND_NOTIFICATIONS = 'The notifications have been successfully sent';

// companies
export const GET_COMPANIES_REJECTED = 'Cannot get companies';
export const GET_COMPANY_REJECTED = 'Cannot get company';
export const CREATE_COMPANY_SUCCESS = 'The company has been successfully created';
export const UPDATE_COMPANY_SUCCESS = 'The company has been successfully updated';
export const UPDATE_MATCHING_CONFIG_SUCCESS = 'The matching config has been successfully updated';
export const CREATE_COMPANY_REJECTED = 'Cannot create company';

// product
export const GET_PRODUCT_CATEGORIES_REJECTED = 'Cannot get product categories';

// organization types
export const GET_ORGANIZATION_TYPES_REJECTED = 'Cannot get organization types';

// locations
export const GET_LOCATIONS_REJECTED = 'Cannot get locations';
export const GET_LOCATION_REJECTED = 'Cannot get location';
export const CREATE_LOCATION_SUCCESS = 'The location has been successfully created';
export const UPDATE_LOCATION_SUCCESS = 'The location has been successfully updated';
export const CREATE_LOCATION_REJECTED = 'Cannot create location';

// file upload
export const UPLOAD_FILE_SUCCESS = 'The files have been successfully uploaded';
export const UPLOAD_FILE_ERROR = 'Some files were not added because they exceed the 2 MB size limit';

// dashboard
export const GET_REQUESTED_DONATIONS_REJECTED = 'Cannot get requested donations';
export const GET_COMPLETED_DONATIONS_REJECTED = 'Cannot get completed donations';
export const GET_DONATIONS_BY_LOCATION_REJECTED = 'Cannot get donations by location';
