import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import axios from 'src/axios';
import { GET_PRODUCT_CATEGORIES_REJECTED } from 'src/constants/snackbar';

import { ErrorResponse } from '../types';
import { formData, FormState, ProductCategory } from '../types/form';

export const initialState: FormState = {
  data: null,
  productCategories: [],
};

export const getProductCategories = createAsyncThunk<ProductCategory[]>(
  'productCategories',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get<ProductCategory[]>('/product-categories');
      return response.data;
    } catch (error) {
      const axiosError = error as AxiosError<ErrorResponse>;
      const message = axiosError.response?.data?.message ?? GET_PRODUCT_CATEGORIES_REJECTED;
      return rejectWithValue(message);
    }
  },
);

const formSlice = createSlice({
  name: 'form',
  initialState,
  reducers: {
    setFormData: (state, action: PayloadAction<formData>) => {
      state.data = action.payload;
    },

    resetForm(state) {
      state.data = initialState.data;
      state.productCategories = initialState.productCategories;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getProductCategories.fulfilled, (state, action: PayloadAction<ProductCategory[]>) => {
      state.productCategories = action.payload;
    });
  },
});

export const { setFormData, resetForm } = formSlice.actions;
export default formSlice.reducer;
