import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

import loginImg from 'src/assets/images/login.png';

export const Container = styled(Box)(() => ({
  display: 'flex',
  width: '100%',
  height: '100%',
}));

export const LeftPart = styled(Box)(() => ({
  padding: '100px',
  width: '44%',
  display: 'flex',
  flexDirection: 'column',
}));

export const Content = styled(Box)(() => ({
  margin: 'auto 0',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}));

export const RightPart = styled(Box)(() => ({
  width: '66%',
  padding: '32px',
}));

export const BgImage = styled('div')(() => ({
  width: '100%',
  height: '100%',
  borderRadius: '24px',
  backgroundImage: `url(${loginImg})`,
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
}));
