import React, { useCallback, useEffect } from 'react';

import { Loader } from '@googlemaps/js-api-loader';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import NavBar from './components/NavBar';
import { ROUTES } from './constants';
import PopoverProvider from './providers/popover/popoverProvider';
import { getMyInfo } from './redux/slices/usersSlice';
import { AppDispatch, RootState } from './redux/store';
import Routes from './routes';
import { getCookie } from './utils/cookie';

const App: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const token = getCookie('token');

  const { user } = useSelector((state: RootState) => state.users);

  const handleGetUser = useCallback(async () => {
    const response = await dispatch(getMyInfo());

    if (getMyInfo.rejected.match(response)) {
      navigate(ROUTES.login);
    }
  }, [dispatch, navigate]);

  useEffect(() => {
    if (token && !user) {
      handleGetUser();
    }
  }, [handleGetUser, navigate, token, user]);

  useEffect(() => {
    const loader = new Loader({
      apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '',
      version: 'weekly',
      libraries: ['places'],
    });

    loader.load();
  }, []);

  return (
    <div className="App">
      <PopoverProvider>
        <NavBar pathname={location.pathname} />
        <Routes />
      </PopoverProvider>
    </div>
  );
};

export default App;
