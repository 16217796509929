import React from 'react';

import Typography from '@mui/material/Typography';
import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import Button from 'src/components/Button';
import { BUTTON_VARIANTS } from 'src/components/Button/types';
import TextField from 'src/components/TextField';
import { PASSWORD_MIN_LENGTH, ROUTES } from 'src/constants';
import { showSnackbar } from 'src/redux/slices/snackbarSlice';
import { resetPassword } from 'src/redux/slices/usersSlice';
import { useQuery } from 'src/utils';

import { AppDispatch, RootState } from '../../redux/store';

import { FormWrapper } from './styled';

interface ResetPasswordForm {
  newPassword: string;
  confirmPassword: string;
}

const INITIAL_VALUES = { newPassword: '', confirmPassword: '' };

const validationSchema = Yup.object({
  newPassword: Yup.string()
    .required('Field is required')
    .min(PASSWORD_MIN_LENGTH, `Password must contain at least ${PASSWORD_MIN_LENGTH} characters`),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('newPassword'), ''], 'Passwords must match')
    .required('Field is required'),
});

const ResetPasswordForm: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const token = useQuery('token');

  const { userLoading } = useSelector((state: RootState) => state.users);

  const handleSubmit = async (values: ResetPasswordForm) => {
    const result = await dispatch(resetPassword({ newPassword: values.newPassword, token }));

    if (resetPassword.fulfilled.match(result)) {
      navigate(ROUTES.login);
    }

    if (resetPassword.rejected.match(result)) {
      dispatch(showSnackbar({ message: result.payload as string, variant: 'error' }));
    }
  };

  return (
    <Formik initialValues={INITIAL_VALUES} validationSchema={validationSchema} onSubmit={handleSubmit}>
      {({ submitForm }) => (
        <Form>
          <FormWrapper>
            <Typography variant="h3" mb="8px">
              Change password
            </Typography>
            <TextField name="newPassword" label="New password" type="password" />
            <TextField name="confirmPassword" label="Repeat password" type="password" />
            <Button
              label="Set password"
              variant={BUTTON_VARIANTS.primaryLarge}
              disabled={userLoading}
              fullWidth
              onClick={submitForm}
            />
          </FormWrapper>
        </Form>
      )}
    </Formik>
  );
};

export default ResetPasswordForm;
