import { POPOVER_MODS } from 'src/types/popover';

export const POPOVER_CONTAINER_WIDTH = {
  medium: '640px',
  small: '360px',
};

export const POPOVER_CONFIGS = {
  [POPOVER_MODS.filter]: {
    title: 'Filters',
    mode: POPOVER_MODS.filter,
    mainBtnLabel: 'Apply',
    secondaryBtnLabel: 'Reset',
    width: POPOVER_CONTAINER_WIDTH.small,
  },
  [POPOVER_MODS.details]: {
    title: 'Details',
    mode: POPOVER_MODS.details,
    mainBtnLabel: 'Edit',
    mainBtnIconName: 'edit',
    secondaryBtnLabel: 'Close',
    width: POPOVER_CONTAINER_WIDTH.medium,
  },
  [POPOVER_MODS.edit]: {
    title: 'Update',
    mode: POPOVER_MODS.edit,
    mainBtnLabel: 'Update',
    secondaryBtnLabel: 'Discard',
    width: POPOVER_CONTAINER_WIDTH.medium,
  },
  [POPOVER_MODS.create]: {
    title: 'Create',
    mode: POPOVER_MODS.create,
    mainBtnLabel: 'Create',
    secondaryBtnLabel: 'Discard',
    width: POPOVER_CONTAINER_WIDTH.medium,
  },
  [POPOVER_MODS.upload]: {
    title: 'Upload',
    mode: POPOVER_MODS.upload,
    mainBtnLabel: 'Upload',
    secondaryBtnLabel: 'Discard',
    width: POPOVER_CONTAINER_WIDTH.small,
  },
};
