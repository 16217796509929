import { configureStore } from '@reduxjs/toolkit';

import logoutMiddleware from './middlewares/logoutMiddleware';
import authReducer from './slices/authSlice';
import companiesSlice from './slices/companiesSlice';
import dashboardSlice from './slices/dashboardSlice';
import donationsSlice from './slices/donationsSlice';
import doneesSlice from './slices/doneesSlice';
import formSlice from './slices/formSlice';
import locationsSlice from './slices/locationsSlice';
import organizationTypesSlice from './slices/organizationTypesSlice';
import snackbarReducer from './slices/snackbarSlice';
import usersSlice from './slices/usersSlice';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    snackbar: snackbarReducer,
    users: usersSlice,
    form: formSlice,
    donees: doneesSlice,
    companies: companiesSlice,
    organizationTypes: organizationTypesSlice,
    locations: locationsSlice,
    donations: donationsSlice,
    dashboard: dashboardSlice,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logoutMiddleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
