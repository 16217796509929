import React, { useContext } from 'react';

import { useTheme } from '@mui/material';
import ListItemText from '@mui/material/ListItemText';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { PopoverContext } from 'src/providers/popover/popoverContext';
import { setCompaniesFilters } from 'src/redux/slices/companiesSlice';
import { setDashboardFilters } from 'src/redux/slices/dashboardSlice';
import { setDonationsFilters } from 'src/redux/slices/donationsSlice';
import { setDoneesFilters } from 'src/redux/slices/doneesSlice';
import { setLocationsFilters } from 'src/redux/slices/locationsSlice';
import { setUsersFilters } from 'src/redux/slices/usersSlice';
import { AppDispatch } from 'src/redux/store';

import Icon from '../Icon';

import { StyledListItem, StyledListItemButton } from './styled';

export type ListItemProps = {
  iconName: string;
  text: string;
  path: string;
  open: boolean;
  pathname: string;
};

const ListItem = ({ iconName, text, path, open, pathname }: ListItemProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const theme = useTheme();

  const { handleClosePopover } = useContext(PopoverContext);

  const isActive = pathname === path;
  const textColor = isActive ? theme.palette.text.primary : theme.palette.common.white;

  const handleNavigate = () => {
    handleClosePopover();
    dispatch(setDashboardFilters(''));
    dispatch(setUsersFilters(''));
    dispatch(setDoneesFilters(''));
    dispatch(setCompaniesFilters(''));
    dispatch(setDonationsFilters(''));
    dispatch(setLocationsFilters(''));
    navigate(path);
  };

  return (
    <StyledListItem isActive={isActive} theme={theme} disablePadding>
      <StyledListItemButton onClick={handleNavigate}>
        <Icon iconName={iconName} htmlColor={textColor} size="24px" data-testid={iconName} />
        <ListItemText primary={text} primaryTypographyProps={{ color: open ? textColor : 'transparent' }} />
      </StyledListItemButton>
    </StyledListItem>
  );
};

export default ListItem;
