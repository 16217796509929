import IconButton from '@mui/material/IconButton';
import { Theme, styled } from '@mui/material/styles';

interface StyledIconButtonProps {
  iconColor: string;
  backgroundColor: string;
  hoverColor: string;
  border: string;
  padding: string;
  theme: Theme;
}

export const StyledIconButton = styled(IconButton, {
  shouldForwardProp: (prop) =>
    prop !== 'iconColor' &&
    prop !== 'backgroundColor' &&
    prop !== 'hoverColor' &&
    prop !== 'padding' &&
    prop !== 'borderColor',
})<StyledIconButtonProps>(({ disabled, iconColor, backgroundColor, hoverColor, border, padding, theme }) => ({
  color: disabled ? theme.palette.secondary.dark : iconColor,
  backgroundColor,
  border: border,
  borderRadius: 12,
  padding: padding,
  width: 'fit-content',
  height: 'fit-content',
  '&:hover': {
    backgroundColor: hoverColor,
  },
  '&.Mui-disabled': { backgroundColor: theme.palette.divider },
}));
