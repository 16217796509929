import { Components } from '@mui/material';

export default {
  styleOverrides: {
    root: {
      width: '320px',
      height: 'auto',
      minHeight: 44,
      whiteSpace: 'pre-line',
      padding: '0 12px',
      display: 'flex',
      alignItems: 'center',
      borderRadius: '8px',
      boxShadow: '0px 0px 2px 0px rgba(0, 0, 0, 0.24), 0px 20px 40px -4px rgba(0, 0, 0, 0.24)',
      fontSize: '14px',
      lineHeight: '26px',
    },
    colorError: '#EF4450',
    action: { padding: '0 0 0 12px' },
  },
} as Components;
