import React, { ReactNode } from 'react';

import Icon from 'src/components/Icon';
import Snackbar from 'src/components/Snackbar';

import { Container, LeftPart, RightPart, BgImage, Content } from './styled';

interface ExternalPageWrapperProps {
  children: ReactNode;
}

const ExternalPageWrapper: React.FC<ExternalPageWrapperProps> = ({ children }) => {
  return (
    <Container>
      <LeftPart>
        <Icon iconName="logo-dark" size="97px" height="40px" viewBox="0 0 97 40" />
        <Content>{children}</Content>
      </LeftPart>
      <RightPart>
        <BgImage />
      </RightPart>
      <Snackbar />
    </Container>
  );
};

export default ExternalPageWrapper;
