import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import axios from 'src/axios';
import { BaseRow } from 'src/components/Table/types';
import { DEFAULT_ERROR, GET_COMPANIES_REJECTED } from 'src/constants/snackbar';
import { Option } from 'src/types';

import { ErrorResponse } from '../types';
import { Company, Companies, CompaniesPayload, CompaniesState, CreateCompanyPayload } from '../types/companies';
import { MatchingConfig, MatchingConfigPayload } from '../types/matchingConfig';

export const initialState: CompaniesState = {
  activeCompany: null,
  companies: {
    data: [],
    currentPage: 0,
    totalPages: 1,
    totalCount: 1,
    availableFilters: [],
  },
  companiesLoading: false,
  companiesError: '',
  companiesFilters: '',
  companyTypes: [],
};

export const getCompanyTypes = createAsyncThunk<Option[]>('companyTypes', async (_, { rejectWithValue }) => {
  try {
    const response = await axios.get<Option[]>('/company-types');
    return response.data;
  } catch (error) {
    const axiosError = error as AxiosError<ErrorResponse>;
    const message = axiosError.response?.data?.message;
    return rejectWithValue(message);
  }
});

export const getCompanies = createAsyncThunk<Companies, CompaniesPayload>(
  'companies',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.get<Companies>('/companies', { params: payload });
      return response.data;
    } catch (error) {
      const axiosError = error as AxiosError<ErrorResponse>;
      const message = axiosError.response?.data?.message ?? GET_COMPANIES_REJECTED;
      return rejectWithValue(message);
    }
  },
);

export const getCompanyById = createAsyncThunk<Company, string>(
  'companies/get-one',
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/companies/${id}`);
      return response.data;
    } catch (error) {
      const axiosError = error as AxiosError<ErrorResponse>;
      const message = axiosError.response?.data?.message ?? DEFAULT_ERROR;
      return rejectWithValue(message);
    }
  },
);

export const updateCompany = createAsyncThunk<Company, CreateCompanyPayload & BaseRow>(
  'companies/update',
  async ({ id, ...data }, { rejectWithValue }) => {
    try {
      const response = await axios.put(`/companies/${id}`, { ...data });
      return response.data;
    } catch (error) {
      const axiosError = error as AxiosError<ErrorResponse>;
      const message = axiosError.response?.data?.message ?? DEFAULT_ERROR;
      return rejectWithValue(message);
    }
  },
);

export const updateMatchingConfig = createAsyncThunk<MatchingConfig, MatchingConfigPayload>(
  'companies/matching-config/update',
  async ({ companyId, id, ...data }, { rejectWithValue }) => {
    try {
      const response = await axios.patch(`/companies/${companyId}/matching-algorithm-configs/${id}`, { ...data });
      return response.data;
    } catch (error) {
      const axiosError = error as AxiosError<ErrorResponse>;
      const message = axiosError.response?.data?.message ?? DEFAULT_ERROR;
      return rejectWithValue(message);
    }
  },
);

export const deleteCompany = createAsyncThunk<unknown, string>('companies/delete', async (id, { rejectWithValue }) => {
  try {
    await axios.delete(`/companies/${id}`);
  } catch (error) {
    const axiosError = error as AxiosError<ErrorResponse>;
    const message = axiosError.response?.data?.message ?? DEFAULT_ERROR;
    return rejectWithValue(message);
  }
});

export const deleteCompanies = createAsyncThunk<unknown, string[]>(
  'companies/delete/bulk',
  async (ids, { rejectWithValue }) => {
    try {
      await axios.delete('/companies/bulk/delete', { data: { ids } });
    } catch (error) {
      const axiosError = error as AxiosError<ErrorResponse>;
      const message = axiosError.response?.data?.message ?? DEFAULT_ERROR;
      return rejectWithValue(message);
    }
  },
);

const companiesSlice = createSlice({
  name: 'companies',
  initialState,
  reducers: {
    setCompaniesFilters(state, action: PayloadAction<string>) {
      state.companiesFilters = action.payload;
    },
    resetCompanies(state) {
      state.companies = initialState.companies;
      state.companiesError = initialState.companiesError;
      state.companiesFilters = initialState.companiesFilters;
      state.companiesLoading = initialState.companiesLoading;
      state.companyTypes = initialState.companyTypes;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCompanies.pending, (state) => {
      state.companiesLoading = true;
      state.companiesError = '';
    });
    builder.addCase(getCompanies.fulfilled, (state, action: PayloadAction<Companies>) => {
      state.companies = action.payload;
      state.companiesLoading = false;
      state.companiesError = '';
    });
    builder.addCase(getCompanies.rejected, (state, action) => {
      state.companies = { ...initialState.companies, availableFilters: state.companies.availableFilters };
      state.companiesLoading = false;
      state.companiesError = action.payload as string;
    });
    builder.addCase(getCompanyById.fulfilled, (state, action: PayloadAction<Company>) => {
      state.activeCompany = action.payload;
    });
    builder.addCase(getCompanyById.rejected, (state) => {
      state.activeCompany = null;
    });
    builder.addCase(updateCompany.pending, (state) => {
      state.companiesLoading = true;
      state.companiesError = '';
    });
    builder.addCase(updateCompany.fulfilled, (state, action: PayloadAction<Company>) => {
      const updatedCompanies = state.companies.data.map((company) =>
        company.id === action.payload.id ? action.payload : company,
      );
      state.companies = { ...state.companies, data: updatedCompanies };
      state.companiesLoading = false;
      state.companiesError = '';
    });
    builder.addCase(updateCompany.rejected, (state, action) => {
      state.companiesLoading = false;
      state.companiesError = action.payload as string;
    });
    builder.addCase(getCompanyTypes.fulfilled, (state, action: PayloadAction<Option[]>) => {
      state.companyTypes = action.payload;
    });
    builder.addCase(updateMatchingConfig.fulfilled, (state, action: PayloadAction<MatchingConfig>) => {
      if (state.activeCompany) {
        state.activeCompany.matchingAlgorithmConfig = action.payload;
      }
    });
  },
});

export const { setCompaniesFilters, resetCompanies } = companiesSlice.actions;

export default companiesSlice.reducer;
