import { Components } from '@mui/material';

export default {
  styleOverrides: {
    paper: {
      padding: 8,
      borderRadius: 16,
      boxShadow: '0px 0px 4px 0px rgba(125, 127, 131, 0.24), 0px 24px 48px 0px rgba(125, 127, 131, 0.24)',
    },
  },
} as Components;
