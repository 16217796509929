import React, { useState } from 'react';

import { ListItemText, useTheme } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { NAV_BAR_ITEMS, NAV_BAR_ITEMS_BY_ROLE, ROUTES } from 'src/constants';
import { logout } from 'src/redux/slices/authSlice';
import { AppDispatch, RootState } from 'src/redux/store';

import Icon from '../Icon';

import ListItem from './ListItem';
import { Drawer, LogoutButton, StyledList } from './styled';

interface NavBarProps {
  pathname: string;
}

const NavBar: React.FC<NavBarProps> = ({ pathname }) => {
  const theme = useTheme();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const { user } = useSelector((state: RootState) => state.users);

  const [open, setOpen] = useState(false);

  const allowedRoutes: string[] = Object.values(NAV_BAR_ITEMS).map((value) => value.path);
  const isNavBarEnabled = allowedRoutes.some((route) => pathname.startsWith(route));

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleLogout = () => {
    dispatch(logout());
    navigate(ROUTES.login);
  };

  if (!user || !isNavBarEnabled) {
    return null;
  }

  return (
    <Drawer
      variant="permanent"
      open={open}
      onMouseEnter={handleDrawerOpen}
      onMouseLeave={handleDrawerClose}
      data-testid="navigation-drawer"
    >
      <Icon
        iconName="logo"
        htmlColor={theme.palette.common.white}
        size={open ? '87px' : '48px'}
        viewBox={open ? '0 0 87 36' : '0 0 48 20'}
        height="36px"
      />

      <StyledList>
        {NAV_BAR_ITEMS_BY_ROLE[user.role].map(({ iconName, text, path }) => (
          <ListItem iconName={iconName} text={text} path={path} open={open} key={text} pathname={pathname} />
        ))}
      </StyledList>

      <LogoutButton onClick={handleLogout} data-testid="sign-out">
        <Icon iconName="sign-out" htmlColor={theme.palette.action.selected} size="24px" />
        <ListItemText
          primary="Sign out"
          primaryTypographyProps={{ color: open ? theme.palette.action.selected : 'transparent' }}
        />
      </LogoutButton>
    </Drawer>
  );
};

export default NavBar;
