import { createContext } from 'react';

import { POPOVER_CONFIGS } from 'src/constants/popover';
import { POPOVER_MODS, PopoverState } from 'src/types/popover';

interface PopoverContext {
  isPopoverOpen: boolean;
  popoverConfig: PopoverState | null;
  handleOpenPopover: () => void;
  handleClosePopover: () => void;
  handleSetPopoverConfig: (config: PopoverState) => void;
}

export const defaultValue: PopoverContext = {
  isPopoverOpen: false,
  popoverConfig: POPOVER_CONFIGS[POPOVER_MODS.filter],
  handleOpenPopover: () => {},
  handleClosePopover: () => {},
  handleSetPopoverConfig: () => {},
};

export const PopoverContext = createContext<PopoverContext>(defaultValue);
