import { Components } from '@mui/material';

import palette from '../palette';

export default {
  styleOverrides: {
    root: {
      width: 'fit-content',
      padding: '4px',
      borderRadius: '8px',
      backgroundColor: 'rgba(125, 127, 131, 0.16)',
    },
    label: { lineHeight: '18px', padding: '3px 8px', color: palette.text?.primary },
    deleteIcon: { marginRight: 0, color: palette.text?.primary, opacity: 0.64 },
    paper: { position: 'inherit' },
  },
} as Components;
