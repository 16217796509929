import { Theme } from '@mui/material';

export interface IconButtonVariants {
  textColor: string;
  backgroundColor: string;
  hoverColor: string;
  border: string;
  padding: string;
  fontSize?: string;
  lineHeight?: string;
}

export interface StyledIconButtonProps extends IconButtonVariants {
  theme: Theme;
}

export enum BUTTON_VARIANTS {
  primaryLarge,
  primary,
  primarySmall,
  primaryAltLarge,
  textPrimary,
  outlinedLarge,
  outlined,
  outlinedSmall,
  red,
}

export interface ButtonProps {
  label: string;
  variant: BUTTON_VARIANTS;
  onClick?: ((e: React.MouseEvent<HTMLButtonElement>) => void) | (() => void);
  disabled?: boolean;
  fullWidth?: boolean;
  iconName?: string;
  size?: string;
  viewBox?: string;
  hasArrow?: boolean;
}
