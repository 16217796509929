import React, { forwardRef } from 'react';

import { Typography, useTheme } from '@mui/material';

import Icon from '../Icon';

import { StyledButton } from './styled';
import { BUTTON_VARIANTS, ButtonProps, IconButtonVariants } from './types';

const Button: React.ForwardRefRenderFunction<HTMLButtonElement, ButtonProps> = (
  {
    label,
    variant = BUTTON_VARIANTS.primary,
    onClick,
    disabled,
    fullWidth,
    iconName,
    size,
    viewBox,
    hasArrow,
    ...props
  },
  ref,
) => {
  const theme = useTheme();

  const VARIANTS_CONFIG: Record<BUTTON_VARIANTS, IconButtonVariants> = {
    [BUTTON_VARIANTS.primaryLarge]: {
      textColor: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
      hoverColor: theme.palette.hover.primaryMain,
      border: 'none',
      padding: '11px 22px',
    },
    [BUTTON_VARIANTS.primaryAltLarge]: {
      textColor: theme.palette.primary.main,
      backgroundColor: theme.palette.customBackground.primaryLight2,
      hoverColor: theme.palette.customHover.primaryAlt,
      border: 'none',
      padding: '11px 22px',
    },
    [BUTTON_VARIANTS.primary]: {
      textColor: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
      hoverColor: theme.palette.hover.primaryMain,
      border: 'none',
      padding: '8px 18px',
    },
    [BUTTON_VARIANTS.textPrimary]: {
      textColor: theme.palette.primary.main,
      backgroundColor: 'transparent',
      hoverColor: 'transparent',
      border: 'none',
      padding: '6px 8px',
    },
    [BUTTON_VARIANTS.outlinedLarge]: {
      textColor: theme.palette.text.primary,
      backgroundColor: 'transparent',
      hoverColor: theme.palette.secondary.light,
      border: `1px solid ${theme.palette.divider}`,
      padding: '11px 22px',
    },
    [BUTTON_VARIANTS.outlined]: {
      textColor: theme.palette.text.primary,
      backgroundColor: 'transparent',
      hoverColor: theme.palette.secondary.light,
      border: `1px solid ${theme.palette.divider}`,
      padding: '8px 18px',
    },
    [BUTTON_VARIANTS.primarySmall]: {
      textColor: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
      hoverColor: theme.palette.hover.primaryMain,
      border: 'none',
      padding: '4px 10px',
      fontSize: '13px',
      lineHeight: '22px',
    },
    [BUTTON_VARIANTS.outlinedSmall]: {
      textColor: theme.palette.text.primary,
      backgroundColor: 'transparent',
      hoverColor: theme.palette.secondary.light,
      border: `1px solid ${theme.palette.divider}`,
      padding: '3px 10px',
      fontSize: '13px',
      lineHeight: '22px',
    },
    [BUTTON_VARIANTS.red]: {
      textColor: theme.palette.common.white,
      backgroundColor: theme.palette.error.main,
      hoverColor: theme.palette.hover.errorMain,
      border: 'none',
      padding: '8px 18px',
    },
  };

  return (
    <StyledButton
      {...props}
      ref={ref}
      onClick={onClick}
      disabled={disabled}
      textColor={VARIANTS_CONFIG[variant].textColor}
      backgroundColor={VARIANTS_CONFIG[variant].backgroundColor}
      hoverColor={VARIANTS_CONFIG[variant].hoverColor}
      padding={VARIANTS_CONFIG[variant].padding}
      border={VARIANTS_CONFIG[variant].border}
      fontSize={VARIANTS_CONFIG[variant].fontSize}
      lineHeight={VARIANTS_CONFIG[variant].lineHeight}
      disableRipple
      theme={theme}
      fullWidth={fullWidth}
      data-testid={`button-${label}`}
    >
      {iconName && (
        <Icon iconName={iconName} size={size} viewBox={viewBox} htmlColor={VARIANTS_CONFIG[variant].textColor} />
      )}
      <Typography variant="button">{label}</Typography>
      {hasArrow && (
        <Icon iconName="chevron-down" size="20px" viewBox="0 0 20 20" htmlColor={VARIANTS_CONFIG[variant].textColor} />
      )}
    </StyledButton>
  );
};

export default forwardRef(Button);
