export interface SelectOption<T> {
  name: T | string;
  displayName: string;
}

export type ListItem = { key: string; displayName: string };

export interface Option {
  name: string;
  displayName: string;
  id: string;
}

export interface Address {
  coordinates: {
    latitude: number;
    longitude: number;
  };
  formattedAddress: string;
  country?: string;
  stateProvince?: string;
  city?: string;
  street?: string;
  zipCode?: string;
}

export interface AddressSuggestion {
  formattedAddress: string;
  getAddress(): Promise<Address>;
}

export interface DateRange {
  startDate: number;
  endDate: number;
}

export enum RangeUnit {
  day = 'day',
  week = 'isoWeek',
  month = 'month',
  year = 'year',
}

export enum PeriodLabels {
  percentageLabel = 'percentageLabel',
  axisLabels = 'axisLabels',
}
