import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import axios from 'src/axios';
import { ErrorResponse } from 'src/redux/types';
import {
  DashboardDateRange,
  DashboardPayload,
  DashboardState,
  DonationStatistics,
  DonationStatisticsRanges,
} from 'src/redux/types/dashboard';
import { ListItem } from 'src/types';
import { Filter } from 'src/types/filter';
import { isBeforeTomorrow } from 'src/utils';

export const initialState: DashboardState = {
  dashboard: {
    availableFilters: [],
    data: {
      donationStatistics: {
        selected: {
          requested: null,
          completed: null,
          active: null,
          weightDiverted: null,
          organizationsHelped: null,
          avgRequestTime: null,
          boxesDonated: null,
          palletsDonated: null,
        },
      },
    },
  },
  dataLoading: false,
  dataError: '',
  dashboardFilters: '',
  dateRange: { startDate: undefined, endDate: undefined, range: null },
};

export const getAvailableFilters = createAsyncThunk<Filter[]>(
  'donations/statistics/availableFilters',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.get<Filter[]>('/donation-statistics/get-available-filters');
      return response.data.map((filter) =>
        filter.key === 'companyIds' || filter.key === 'locationIds' ? { ...filter, disabled: true } : filter,
      );
    } catch (error) {
      const axiosError = error as AxiosError<ErrorResponse>;
      const message = axiosError.response?.data?.message;

      return rejectWithValue(message);
    }
  },
);

export const getDonationStatistics = createAsyncThunk<DonationStatistics[], DashboardPayload>(
  'donations/statistics',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.get<DonationStatistics[]>('/donation-statistics/get-donation-statistics', {
        params: payload,
      });
      const { startDate, endDate } = payload;
      const shouldChangeOrder = startDate && endDate && !isBeforeTomorrow(startDate, endDate);
      if (shouldChangeOrder) {
        return [response.data[DonationStatisticsRanges.additional], response.data[DonationStatisticsRanges.selected]];
      }
      return response.data;
    } catch (error) {
      const axiosError = error as AxiosError<ErrorResponse>;
      const message = axiosError.response?.data?.message;

      return rejectWithValue(message);
    }
  },
);

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setDashboardFilters(state, action: PayloadAction<string>) {
      state.dashboardFilters = action.payload;
    },
    setDateRange(state, action: PayloadAction<DashboardDateRange>) {
      state.dateRange.startDate = action.payload.startDate;
      state.dateRange.endDate = action.payload.endDate;
      state.dateRange.range = action.payload.range;
    },

    setAvailableFiltersValues(state, action: PayloadAction<{ companies?: ListItem[]; locations?: ListItem[] }>) {
      state.dashboard.availableFilters = state.dashboard.availableFilters.map((filter) => {
        if (filter.key === 'companyIds' && Array.isArray(action.payload.companies)) {
          return { ...filter, values: action.payload.companies, disabled: !action.payload.companies.length };
        }
        if (filter.key === 'locationIds' && Array.isArray(action.payload.locations)) {
          return { ...filter, values: action.payload.locations, disabled: !action.payload.locations.length };
        }
        return filter;
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAvailableFilters.fulfilled, (state, action: PayloadAction<Filter[]>) => {
      state.dashboard.availableFilters = action.payload;
      state.dataLoading = false;
      state.dataError = '';
    });
    builder.addCase(getAvailableFilters.rejected, (state) => {
      state.dashboard.availableFilters = [];
    });
    builder.addCase(getDonationStatistics.pending, (state) => {
      state.dataLoading = true;
      state.dataError = '';
    });
    builder.addCase(getDonationStatistics.fulfilled, (state, action: PayloadAction<DonationStatistics[]>) => {
      state.dashboard.data.donationStatistics = {
        selected: action.payload[DonationStatisticsRanges.selected],
        additional: action.payload[DonationStatisticsRanges.additional],
      };
      state.dataLoading = false;
      state.dataError = '';
    });
    builder.addCase(getDonationStatistics.rejected, (state, action) => {
      state.dashboard.data.donationStatistics = initialState.dashboard.data.donationStatistics;
      state.dataLoading = false;
      state.dataError = action.payload as string;
    });
  },
});

export const { setDashboardFilters, setDateRange, setAvailableFiltersValues } = dashboardSlice.actions;

export default dashboardSlice.reducer;
