import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import axios from 'src/axios';
import { DEFAULT_ERROR, GET_DONEES_REJECTED } from 'src/constants/snackbar';
import { ListItem } from 'src/types';

import { ErrorResponse } from '../types';
import { Donee, Donees, DoneesPayload, DoneesState } from '../types/donees';

export const initialState: DoneesState = {
  donees: {
    data: [],
    currentPage: 0,
    totalPages: 1,
    totalCount: 1,
    availableFilters: [],
  },
  doneesLoading: false,
  doneesError: '',
  doneesFilters: '',
  deliveryMethods: [],
  prefferedContactMethods: [],
  donee: null,
  doneeLoading: false,
  doneeError: '',
};

export const getDonees = createAsyncThunk<Donees, DoneesPayload>('donees', async (payload, { rejectWithValue }) => {
  try {
    const response = await axios.get<Donees>('/donees', { params: payload });
    return response.data;
  } catch (error) {
    const axiosError = error as AxiosError<ErrorResponse>;
    const message = axiosError.response?.data?.message ?? GET_DONEES_REJECTED;
    return rejectWithValue(message);
  }
});

export const getDonee = createAsyncThunk<Donee, string>('donees/get-one', async (id, { rejectWithValue }) => {
  try {
    const response = await axios.get(`/donees/${id}`);
    return response.data;
  } catch (error) {
    const axiosError = error as AxiosError<ErrorResponse>;
    const message = axiosError.response?.data?.message ?? DEFAULT_ERROR;
    return rejectWithValue(message);
  }
});

export const updateDonee = createAsyncThunk<Donee, Donee>(
  'donees/updateyId',
  async ({ id, ...data }, { rejectWithValue }) => {
    try {
      const response = await axios.put(`/donees/${id}`, { ...data });
      return response.data;
    } catch (error) {
      const axiosError = error as AxiosError<ErrorResponse>;
      const message = axiosError.response?.data?.message ?? DEFAULT_ERROR;
      return rejectWithValue(message);
    }
  },
);

export const deleteDonee = createAsyncThunk<unknown, string>('donees/delete', async (id, { rejectWithValue }) => {
  try {
    await axios.delete(`/donees/${id}`);
  } catch (error) {
    const axiosError = error as AxiosError<ErrorResponse>;
    const message = axiosError.response?.data?.message ?? DEFAULT_ERROR;
    return rejectWithValue(message);
  }
});

export const deleteDonees = createAsyncThunk<unknown, string[]>(
  'donees/delete/bulk',
  async (ids, { rejectWithValue }) => {
    try {
      await axios.delete('/donees/bulk/delete', { data: { ids } });
    } catch (error) {
      const axiosError = error as AxiosError<ErrorResponse>;
      const message = axiosError.response?.data?.message ?? DEFAULT_ERROR;
      return rejectWithValue(message);
    }
  },
);

export const getDeliveryMethods = createAsyncThunk<ListItem[]>('deliveryMethods', async (_, { rejectWithValue }) => {
  try {
    const response = await axios.get<ListItem[]>('/donees/delivery-methods/list');
    return response.data;
  } catch (error) {
    const axiosError = error as AxiosError<ErrorResponse>;
    const message = axiosError.response?.data?.message;
    return rejectWithValue(message);
  }
});

export const getPreferredContactMethods = createAsyncThunk<ListItem[]>(
  'preferredContactMethods',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get<ListItem[]>('/donees/preferred-contact-methods/list');
      return response.data;
    } catch (error) {
      const axiosError = error as AxiosError<ErrorResponse>;
      const message = axiosError.response?.data?.message;
      return rejectWithValue(message);
    }
  },
);

const doneesSlice = createSlice({
  name: 'donees',
  initialState,
  reducers: {
    setDoneesFilters(state, action: PayloadAction<string>) {
      state.doneesFilters = action.payload;
    },
    resetDonees(state) {
      state.donees = initialState.donees;
      state.doneesError = initialState.doneesError;
      state.doneesLoading = initialState.doneesLoading;
      state.doneesFilters = initialState.doneesFilters;
      state.deliveryMethods = initialState.deliveryMethods;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getDonees.pending, (state) => {
      state.doneesLoading = true;
      state.doneesError = '';
    });
    builder.addCase(getDonees.fulfilled, (state, action: PayloadAction<Donees>) => {
      state.donees = action.payload;
      state.doneesLoading = false;
      state.doneesError = '';
    });
    builder.addCase(getDonees.rejected, (state, action) => {
      state.donees = { ...initialState.donees, availableFilters: state.donees.availableFilters };
      state.doneesLoading = false;
      state.doneesError = action.payload as string;
    });
    builder.addCase(updateDonee.pending, (state) => {
      state.doneesLoading = true;
      state.doneesError = '';
    });
    builder.addCase(updateDonee.fulfilled, (state, action: PayloadAction<Donee>) => {
      const updatedDonees = state.donees.data.map((donee) => (donee.id === action.payload.id ? action.payload : donee));
      state.donees = { ...state.donees, data: updatedDonees };
      state.doneesLoading = false;
      state.doneesError = '';
    });
    builder.addCase(updateDonee.rejected, (state, action) => {
      state.doneesLoading = false;
      state.doneesError = action.payload as string;
    });
    builder.addCase(getDeliveryMethods.fulfilled, (state, action: PayloadAction<ListItem[]>) => {
      state.deliveryMethods = action.payload;
    });
    builder.addCase(getPreferredContactMethods.fulfilled, (state, action: PayloadAction<ListItem[]>) => {
      state.prefferedContactMethods = action.payload;
    });
    builder.addCase(getDonee.pending, (state) => {
      state.doneeLoading = true;
      state.doneeError = '';
    });
    builder.addCase(getDonee.fulfilled, (state, action: PayloadAction<Donee>) => {
      state.donee = action.payload;
      state.doneeLoading = false;
      state.doneeError = '';
    });
    builder.addCase(getDonee.rejected, (state, action) => {
      state.donee = null;
      state.doneeLoading = false;
      state.doneeError = action.payload as string;
    });
  },
});

export const { setDoneesFilters, resetDonees } = doneesSlice.actions;

export default doneesSlice.reducer;
