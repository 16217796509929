import { Components } from '@mui/material';

export default {
  styleOverrides: {
    root: {},
    inputRoot: {
      padding: '0 16px',
    },
  },
} as Components;
