import { Components } from '@mui/material';

export default {
  styleOverrides: {
    root: {
      '& .MuiSvgIcon-root.MuiTableSortLabel-icon': {
        opacity: 0.2,
      },
      '& .MuiSvgIcon-root.MuiTableSortLabel-icon:hover': {
        opacity: 0.6,
      },
      '&.MuiTableSortLabel-root.Mui-active .MuiTableSortLabel-icon': {
        opacity: 1,
      },
    },
  },
} as Components;
