export default {
  styleOverrides: {
    root: {
      height: 'unset',
      '.MuiButtonBase-root': {
        top: '6px',
        left: '6px',
      },
      '.MuiButtonBase-root.Mui-checked': {
        left: '0',

        '.MuiSwitch-thumb': {
          background: 'white',
        },

        '+.MuiSwitch-track': {
          opacity: 1,
        },
      },
    },
    track: {
      background: 'rgba(125, 127, 131, 0.48)',
      opacity: 1,
      height: '20px',
      width: '33px',
      borderRadius: '16px',
    },
    thumb: {
      height: '14px',
      width: '14px',
    },
  },
};
