import { RangeUnit } from 'src/types';
import { Filter } from 'src/types/filter';

export interface DonationStatistics {
  requested: number | null;
  completed: number | null;
  active: number | null;
  weightDiverted: number | null;
  organizationsHelped: number | null;
  avgRequestTime: number | null;
  boxesDonated: number | null;
  palletsDonated: number | null;
}

export interface Data {
  donationStatistics: { additional?: DonationStatistics; selected: DonationStatistics };
}

export interface Dashboard {
  data: Data;
  availableFilters: Filter[];
}

export type DashboardRange = RangeUnit | null;

export interface DashboardDateRange {
  startDate?: number;
  endDate?: number;
  range: DashboardRange;
}

export interface DashboardState {
  dashboard: Dashboard;
  dataLoading: boolean;
  dataError: string;
  dashboardFilters: string;
  dateRange: DashboardDateRange;
}

export interface DashboardPayload {
  companyIds?: string;
  locationIds?: string;
  states?: string;
  startDate?: number;
  endDate?: number;
  filters?: string;
}

export enum DonationStatisticsRanges {
  selected,
  additional,
}

export interface GroupedDonationsCount {
  group: string;
  count: number;
}

export type GroupedDonationsCountResponse = GroupedDonationsCount[];

export interface GroupedByStateDonations {
  state: string;
  items: GroupedDonationsCount[];
}

export type GroupedByStateDonationsResponse = GroupedByStateDonations[];

export enum GroupBy {
  company = 'company',
  location = 'location',
  doneeOrgType = 'doneeOrgType',
  year = 'year',
  month = 'month',
  day = 'day',
}
