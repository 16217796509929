import dayjs from 'dayjs';
import { useLocation } from 'react-router-dom';

import { MONTHS_SHORT, WEEK_DAYS_SHORT } from 'src/constants';
import { DashboardRange, GroupBy } from 'src/redux/types/dashboard';
import { PeriodLabels, RangeUnit } from 'src/types';

interface GetPeriodLabelProps {
  range: DashboardRange;
  hasStartDate: boolean;
  mode: PeriodLabels;
  initialLabels?: string[];
}

export const useQuery = (name: string) => {
  const params = new URLSearchParams(useLocation().search);
  return params.get(name) ?? '';
};

export const getUrlExtension = (url: string) => url.split(/[#?]/)[0].split('.').pop()?.trim();

export const getValue = (value?: string | number) => (value || value === 0 ? value : '-');

export const getTimestamp = (value: Date, mode: 'start' | 'end') =>
  mode === 'start'
    ? Date.UTC(value.getFullYear(), value.getMonth(), value.getDate(), 0, 0, 0, 0) / 1000
    : Date.UTC(value.getFullYear(), value.getMonth(), value.getDate(), 23, 59, 59, 0) / 1000;

export const getYesterdayTimestamp = (timestamp: number) => {
  const secondsInADay = 24 * 60 * 60;
  return timestamp - secondsInADay;
};

export const getDaysInMonth = (): string[] => {
  const now = dayjs();
  const daysInMonth = now.daysInMonth();
  const daysArray = Array.from({ length: daysInMonth }, (v, i) => now.date(i + 1).format('D MMM'));
  return daysArray;
};

export const getPeriodLabel = ({
  range,
  hasStartDate,
  mode,
  initialLabels = [],
}: GetPeriodLabelProps): string | string[] => {
  // custom selection
  if (range === null && hasStartDate) {
    return mode === PeriodLabels.percentageLabel
      ? 'than selected range'
      : initialLabels?.map((label) => dayjs(label).format('D MMM YYYY'));
  }
  // all time
  if (range === null && !hasStartDate) {
    return mode === PeriodLabels.percentageLabel ? 'than selected range' : initialLabels;
  }
  // today
  if (range === RangeUnit.day) {
    return mode === PeriodLabels.percentageLabel ? 'than yesterday' : ['Yesterday', 'Today'];
  }
  // this week
  if (range === RangeUnit.week) {
    return mode === PeriodLabels.percentageLabel ? 'than last week' : WEEK_DAYS_SHORT;
  }
  // this month
  if (range === RangeUnit.month) {
    return mode === PeriodLabels.percentageLabel ? 'than last month' : getDaysInMonth();
  }
  // this year
  if (range === RangeUnit.year) {
    return mode === PeriodLabels.percentageLabel ? 'than last year' : MONTHS_SHORT;
  }
  return mode === PeriodLabels.percentageLabel ? '' : [];
};

export const getGroupByPeriod = (range: DashboardRange, hasStartDate: boolean): GroupBy => {
  if (range === RangeUnit.year) {
    return GroupBy.month;
  }
  // all time
  if (range === null && !hasStartDate) {
    return GroupBy.month;
  }
  return GroupBy.day;
};

export const getPercentage = (
  selectedValue: number | null,
  additionalValue?: number | null,
): { percentage: number | null; isNegative: boolean | null } => {
  if (selectedValue === null || additionalValue === null || additionalValue === undefined) {
    return { percentage: null, isNegative: null };
  }

  if ((selectedValue === 0 && additionalValue === 0) || additionalValue === 0) {
    return { percentage: 0, isNegative: false };
  }

  const percentage = (selectedValue / additionalValue) * 100;

  const isNegative = selectedValue < additionalValue;

  return { percentage: parseFloat(percentage.toFixed(2)), isNegative };
};

export const isBeforeTomorrow = (startDate: number, endDate: number) => {
  const now = dayjs().startOf('day');
  const start = dayjs(startDate).startOf('day');
  const end = dayjs(endDate).endOf('day');
  const tomorrow = dayjs().add(1, 'day').startOf('day');

  return now.isSameOrAfter(start) && end.isAfter(tomorrow);
};

export const formatDuration = (seconds: number) => {
  const dayInSeconds = 86400;

  if (seconds < dayInSeconds) {
    return '<1 day';
  }

  const dur = dayjs.duration(seconds, 'seconds');

  const years = Math.floor(dur.asYears());
  const months = Math.floor(dur.asMonths()) % 12;
  const days = dur.days() % 30; // Assuming 30 days per month for approximation

  let result = '';
  if (years > 0) {
    result += `${years} yr, `;
  }
  if (months > 0) {
    result += `${months} mo, `;
  }
  result += `${days} d`;

  return result;
};

export const getFullName = (firstName?: string, lastName?: string): string => {
  if (firstName && lastName) {
    return `${firstName} ${lastName}`;
  } else if (firstName) {
    return firstName;
  } else if (lastName) {
    return lastName;
  } else {
    return '-';
  }
};
