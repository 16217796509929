import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import axios from 'src/axios';
import { GET_ORGANIZATION_TYPES_REJECTED } from 'src/constants/snackbar';

import { ErrorResponse } from '../types';
import { OrganizationType, OrganizationTypesState } from '../types/organizationTypes';

export const initialState: OrganizationTypesState = {
  organizationTypes: [],
};

export const getOrganizationTypes = createAsyncThunk<OrganizationType[]>(
  'organizationTypes',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get<OrganizationType[]>('/organization-types');
      return response.data;
    } catch (error) {
      const axiosError = error as AxiosError<ErrorResponse>;
      const message = axiosError.response?.data?.message ?? GET_ORGANIZATION_TYPES_REJECTED;
      return rejectWithValue(message);
    }
  },
);

const organizationTypesSlice = createSlice({
  name: 'organizationTypes',
  initialState,
  reducers: {
    resetOrgTypes(state) {
      state.organizationTypes = initialState.organizationTypes;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getOrganizationTypes.fulfilled, (state, action: PayloadAction<OrganizationType[]>) => {
      state.organizationTypes = action.payload;
    });
  },
});

export const { resetOrgTypes } = organizationTypesSlice.actions;

export default organizationTypesSlice.reducer;
