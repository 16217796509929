import React from 'react';

import { useTheme } from '@mui/material';

import Icon from '../Icon';

import { StyledIconButton } from './styled';

export enum ICON_BUTTON_VARIANTS {
  primaryMedium,
  outlinedMedium,
  outlinedMedium2,
  primarySmall,
  primaryOutlinedSmall,
  outlinedSmall,
  redSmall,
  redOutlinedSmall,
  transparentMedium,
  transparentSmall,
}

interface IconButtonProps {
  iconName: string;
  variant: ICON_BUTTON_VARIANTS;
  onClick?: () => void;
  disabled?: boolean;
}

const IconButton: React.FC<IconButtonProps> = ({ iconName, variant, disabled, onClick }) => {
  const theme = useTheme();

  const VARIANTS_CONFIG = {
    [ICON_BUTTON_VARIANTS.primaryMedium]: {
      iconColor: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
      hoverColor: theme.palette.hover.primaryMain,
      border: 'none',
      padding: '8px',
      size: '24px',
      viewBox: '0 0 24 24',
    },
    [ICON_BUTTON_VARIANTS.outlinedMedium]: {
      iconColor: theme.palette.text.primary,
      backgroundColor: 'transparent',
      hoverColor: theme.palette.secondary.light,
      border: `1px solid ${theme.palette.divider}`,
      padding: '7px',
      size: '24px',
      viewBox: '0 0 24 24',
    },
    [ICON_BUTTON_VARIANTS.outlinedMedium2]: {
      iconColor: theme.palette.customGrey.icon,
      backgroundColor: 'transparent',
      hoverColor: theme.palette.secondary.light,
      border: `1px solid ${theme.palette.divider}`,
      padding: '7px',
      size: '20px',
      viewBox: '0 0 20 20',
    },
    [ICON_BUTTON_VARIANTS.transparentMedium]: {
      iconColor: theme.palette.customGrey.icon,
      backgroundColor: 'transparent',
      hoverColor: theme.palette.secondary.light,
      border: 'none',
      padding: '7px',
      size: '24px',
      viewBox: '0 0 24 24',
    },
    [ICON_BUTTON_VARIANTS.primarySmall]: {
      iconColor: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
      hoverColor: theme.palette.hover.primaryMain,
      border: 'none',
      padding: '6px',
      size: '18px',
      viewBox: '0 0 18 18',
    },
    [ICON_BUTTON_VARIANTS.primaryOutlinedSmall]: {
      iconColor: theme.palette.primary.main,
      backgroundColor: 'transparent',
      hoverColor: theme.palette.primary.light,
      border: `1px solid ${disabled ? theme.palette.divider : theme.palette.primary.main}`,
      padding: '6px',
      size: '18px',
      viewBox: '0 0 18 18',
    },
    [ICON_BUTTON_VARIANTS.outlinedSmall]: {
      iconColor: theme.palette.text.primary,
      backgroundColor: 'transparent',
      hoverColor: theme.palette.secondary.light,
      border: `1px solid ${theme.palette.divider}`,
      padding: '6px',
      size: '18px',
      viewBox: '0 0 18 18',
    },
    [ICON_BUTTON_VARIANTS.redSmall]: {
      iconColor: theme.palette.common.white,
      backgroundColor: theme.palette.error.main,
      hoverColor: theme.palette.hover.errorMain,
      border: 'none',
      padding: '6px',
      size: '18px',
      viewBox: '0 0 18 18',
    },
    [ICON_BUTTON_VARIANTS.redOutlinedSmall]: {
      iconColor: theme.palette.red.text,
      backgroundColor: 'transparent',
      hoverColor: theme.palette.customHover.red,
      border: `1px solid ${theme.palette.red.background}`,
      padding: '6px',
      size: '18px',
      viewBox: '0 0 18 18',
    },
    [ICON_BUTTON_VARIANTS.transparentSmall]: {
      iconColor: theme.palette.customGrey.icon,
      backgroundColor: 'transparent',
      hoverColor: theme.palette.secondary.light,
      border: 'none',
      padding: '6px',
      size: '18px',
      viewBox: '0 0 18 18',
    },
  };

  return (
    <StyledIconButton
      data-testid={`icon-button-${iconName}`}
      onClick={onClick}
      disabled={disabled}
      iconColor={VARIANTS_CONFIG[variant].iconColor}
      backgroundColor={VARIANTS_CONFIG[variant].backgroundColor}
      hoverColor={VARIANTS_CONFIG[variant].hoverColor}
      padding={VARIANTS_CONFIG[variant].padding}
      border={VARIANTS_CONFIG[variant].border}
      theme={theme}
      disableRipple
    >
      <Icon iconName={iconName} size={VARIANTS_CONFIG[variant].size} viewBox={VARIANTS_CONFIG[variant].viewBox} />
    </StyledIconButton>
  );
};

export default IconButton;
