import { Components } from '@mui/material';

export default {
  styleOverrides: {
    root: {
      fontSize: 14,
      lineHeight: '26px',
      borderRadius: 8,
      '&.Mui-selected': {
        backgroundColor: 'rgba(30, 174, 161, 0.12)',
      },
    },
  },
} as Components;
